import { createFormikValidation, regexEmail } from "../../utils/Utils";

export const validateFormCadastro = (values) => {
	let formMap = new Map();

	formMap.set('email', 'email');
	formMap.set('password', 'senha');
	formMap.set('pro_nome', 'nome');
	formMap.set('pro_celular', 'celular');
	formMap.set('pro_sexo', 'sexo');
	formMap.set('pro_data_nascimento', 'data de nascimento');
	formMap.set('pro_cep', 'cep');
	formMap.set('pro_endereco', 'endereco');
	formMap.set('pro_numero', 'numero');
	formMap.set('pro_bairro', 'bairro');
	formMap.set('pro_estado', 'estado');
	formMap.set('pro_cidade', 'cidade');
	formMap.set('pro_tipo_documento', 'tipo do documento');
	formMap.set('pro_numero_documento', 'número do documento');
	formMap.set('pro_especialidade', 'especialidade');
	formMap.set('pro_clinica', 'clínica');

	let errors = createFormikValidation(formMap, values);

	if (!regexEmail.test(values.email)) {
		errors.email = "Você precisa informar um email válido.";
	}

	return errors;
};

export const validateLogin = (values) => {
	let errors = {};

	if (!values.email) {
		errors.email = "O email é uma informação obrigatória.";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
		errors.email = "Você precisa informar um email válido.";
	}

	if (!values.password) {
		errors.password = "A senha é uma informação obrigatória.";
	}

	return errors;
};

export const validateAlterarSenha = (values) => {
	let errors = {};

	if (!values.password) {
		errors.password = "A senha é uma informação obrigatória.";
	} else if (values.password.length < 6) {
		errors.password = "Sua nova senha precisa ter no mínimo 6 caracteres.";
	}

	if (!values.password_confirmation) {
		errors.password_confirmation = "A confirmação de senha é uma informação obrigatória.";
	}

	if (values.password !== values.password_confirmation) {
		errors.password_confirmation = "As senhas não são iguais.";
	}

	return errors;
};

export const validateEsqueceuSenha = (values) => {
	let errors = {};

	if (!values.email) {
		errors.email = "O email é uma informação obrigatória.";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
		errors.email = "Você precisa informar um email válido.";
	}

	return errors;
};