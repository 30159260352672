import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { register } from "../../crud/auth.crud";
import Swal from "sweetalert2";
import { Col, Row } from "react-bootstrap";
import InputMask from 'react-input-mask';
import { Link, Redirect } from "react-router-dom";
import { buscaCep } from "../../utils/Utils";
import { validateFormCadastro } from "./AuthUtils";

function Register() {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [step, setStep] = useState('primeiro');

	const submitCadastro = (values, setStatus) => {
		setLoading(true);

		register(values).then(res => {
			if (res.status === 200) {
				setLoading(false);

				Swal.fire('Sucesso', 'O usuário foi cadastrado com sucesso. Realize o login na plataforma.', 'success');

				setSuccess(true);
			}
		}).catch(err => {
			setLoading(false);

			let error = err?.response?.data?.errors?.length > 0
				? err?.response?.data?.errors[0]
				: null;

			if (!error) {
				return Swal.fire('Ops', 'Ocorreu um erro ao tentar realizar o seu cadastro. Por favor, entre em contato com o suporte.', 'error');
			}

			setStatus(error)
		});
	};

	const getInitialValues = () => {
		return {
			email: '',
			password: '',
			pro_nome: '',
			pro_tipo_documento: '',
			pro_especialidade: '',
			pro_numero_documento: '',
			pro_data_nascimento: '',
			pro_sexo: 'Masculino',
			pro_clinica: '',
			pro_celular: '',
			pro_cep: '',
			pro_endereco: '',
			pro_numero: '',
			pro_bairro: '',
			pro_complemento: '',
			pro_estado: '',
			pro_cidade: '',
		};
	}

	return (
		<>
			<div className="kt-login__head">
			</div>

			<div className="kt-login__body">
				<div className="kt-login__form">
					<div className="kt-login__title">
						<h3>Profissional - Registrar</h3>
					</div>

					{success && <Redirect to="/login" />}

					<Formik initialValues={getInitialValues()} validate={values => validateFormCadastro(values)} onSubmit={(values, { setStatus }) => submitCadastro(values, setStatus)}>
						{({ values, status, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
							<form noValidate={true} autoComplete="off" className="kt-form" onSubmit={handleSubmit}>
								{status &&
									<div role="alert" className="alert alert-danger">
										<div className="alert-text">{status}</div>
									</div>
								}

								{step === 'primeiro' &&
									<>
										<Row>
											<Col sm={12}>
												<TextField label="Nome *" margin="normal" name="pro_nome" onBlur={handleBlur} onChange={handleChange}
													value={values.pro_nome} helperText={touched.pro_nome && errors.pro_nome} error={Boolean(touched.pro_nome && errors.pro_nome)} />
											</Col>
										</Row>

										<Row>
											<Col sm={6}>
												<TextField type="email" label="Email *" margin="normal" name="email" onBlur={handleBlur} onChange={handleChange}
													value={values.email} helperText={touched.email && errors.email} error={Boolean(touched.email && errors.email)} />
											</Col>

											<Col sm={6}>
												<TextField label="Conselho *" margin="normal" name="pro_tipo_documento" onBlur={handleBlur} onChange={handleChange}
													value={values.pro_tipo_documento} helperText={touched.pro_tipo_documento && errors.pro_tipo_documento} error={Boolean(touched.pro_tipo_documento && errors.pro_tipo_documento)} />
											</Col>
										</Row>

										<Row>
											<Col sm={6}>
												<TextField label="Especialidade *" margin="normal" name="pro_especialidade" onBlur={handleBlur} onChange={handleChange}
													value={values.pro_especialidade} helperText={touched.pro_especialidade && errors.pro_especialidade} error={Boolean(touched.pro_especialidade && errors.pro_especialidade)} />
											</Col>

											<Col sm={6}>
												<TextField label="Número do documento *" margin="normal" name="pro_numero_documento" onBlur={handleBlur} onChange={handleChange}
													value={values.pro_numero_documento} helperText={touched.pro_numero_documento && errors.pro_numero_documento} error={Boolean(touched.pro_numero_documento && errors.pro_numero_documento)} />
											</Col>
										</Row>

										<Row>
											<Col sm={6}>
												<TextField label="Data de nascimento *" type="date" margin="normal" name="pro_data_nascimento" InputLabelProps={{ shrink: true }} onBlur={handleBlur} onChange={handleChange}
													value={values.pro_data_nascimento} helperText={touched.pro_data_nascimento && errors.pro_data_nascimento} error={Boolean(touched.pro_data_nascimento && errors.pro_data_nascimento)} />
											</Col>
											<Col sm={6} className="mt-2">
												<FormControl>
													<FormLabel id="demo-controlled-radio-buttons-group">Sexo</FormLabel>
													<RadioGroup row aria-labelledby="row-radio-buttons-group" name="pro_sexo" value={values.pro_sexo} onChange={handleChange} className="d-flex justify-content-between" >
														<FormControlLabel value="Feminino" control={<Radio />} label="Feminino" />
														<FormControlLabel value="Masculino" control={<Radio />} label="Masculino" />
													</RadioGroup>
												</FormControl>
											</Col>
										</Row>

										<Row>
											<Col sm={12}>
												<TextField label="Senha *" type="password" margin="normal" name="password" onBlur={handleBlur} onChange={handleChange}
													value={values.password} helperText={touched.password && errors.password} error={Boolean(touched.password && errors.password)} />
											</Col>
										</Row>
									</>

								}

								{step === 'segundo' &&
									<>
										<Row>
											<Col sm={6}>
												<TextField label="Clínica *" margin="normal" name="pro_clinica" onBlur={handleBlur} onChange={handleChange}
													value={values.pro_clinica} helperText={touched.pro_clinica && errors.pro_clinica} error={Boolean(touched.pro_clinica && errors.pro_clinica)} />
											</Col>

											<Col sm={6}>
												<InputMask mask="(99) 99999-9999" onBlur={handleBlur} onChange={handleChange} value={values.pro_celular}>
													{() =>
														<TextField label="Telefone *" margin="normal" name="pro_celular" onBlur={handleBlur} onChange={handleChange}
															value={values.pro_celular} helperText={touched.pro_celular && errors.pro_celular} error={Boolean(touched.pro_celular && errors.pro_celular)} />
													}
												</InputMask>
											</Col>
										</Row>

										<Row>
											<Col sm={12}>
												<InputMask mask="99999-999" onBlur={(e) => handleBlur(buscaCep(e, setFieldValue, 'pro_endereco', 'pro_bairro', 'pro_cidade', 'pro_estado'))} onChange={handleChange} value={values.pro_cep}>
													{() =>
														<TextField label="CEP *" margin="normal" name="pro_cep"
															value={values.pro_cep} helperText={touched.pro_cep && errors.pro_cep} error={Boolean(touched.pro_cep && errors.pro_cep)} />
													}
												</InputMask>
											</Col>
										</Row>

										<Row>
											<Col sm={8}>
												<TextField label="Endereço *" margin="normal" name="pro_endereco" onBlur={handleBlur} onChange={handleChange}
													value={values.pro_endereco} helperText={touched.pro_endereco && errors.pro_endereco} error={Boolean(touched.pro_endereco && errors.pro_endereco)} />
											</Col>

											<Col sm={4}>
												<TextField label="Número *" margin="normal" name="pro_numero" onBlur={handleBlur} onChange={handleChange}
													value={values.pro_numero} helperText={touched.pro_numero && errors.pro_numero} error={Boolean(touched.pro_numero && errors.pro_numero)} />
											</Col>
										</Row>

										<Row>
											<Col sm={6}>
												<TextField label="Bairro *" margin="normal" name="pro_bairro" onBlur={handleBlur} onChange={handleChange}
													value={values.pro_bairro} helperText={touched.pro_bairro && errors.pro_bairro} error={Boolean(touched.pro_bairro && errors.pro_bairro)} />
											</Col>

											<Col sm={6}>
												<TextField label="Complemento " margin="normal" name="pro_complemento" onBlur={handleBlur} onChange={handleChange}
													value={values.pro_complemento} helperText={touched.pro_complemento && errors.pro_complemento} error={Boolean(touched.pro_complemento && errors.pro_complemento)} />
											</Col>
										</Row>

										<Row>
											<Col sm={6}>
												<TextField label="Estado *" margin="normal" name="pro_estado" onBlur={handleBlur} onChange={handleChange}
													value={values.pro_estado} helperText={touched.pro_estado && errors.pro_estado} error={Boolean(touched.pro_estado && errors.pro_estado)} />
											</Col>

											<Col sm={6}>
												<TextField label="Cidade *" margin="normal" name="pro_cidade" onBlur={handleBlur} onChange={handleChange}
													value={values.pro_cidade} helperText={touched.pro_cidade && errors.pro_cidade} error={Boolean(touched.pro_cidade && errors.pro_cidade)} />
											</Col>
										</Row>
									</>
								}


								<div className="mt-5">
									{step === 'primeiro' &&
										<div className="d-flex justify-content-between">
											<Link className="btn btn-danger btn-bold" to="/"><i className="fa fa-arrow-left margin-icon"></i><b>Login</b></Link>

											<button disabled={loading} type="button" className="btn btn-primary btn-bold d-flex justify-content-end" onClick={() => setStep('segundo')}>
												<i className={`margin-icon ${loading ? "fas fa-sync fa-spin" : "fa fa-arrow-right"}`} />Avançar
											</button>
										</div>
									}

									{step === 'segundo' &&
										<div className="d-flex justify-content-between">
											<button disabled={loading} type="button" className="btn btn-danger btn-bold" onClick={() => setStep('primeiro')}>
												<i className={`margin-icon fa fa-arrow-left`} />Voltar
											</button>

											<button disabled={loading} type="submit" className="btn btn-success btn-bold">
												<i className={`margin-icon ${loading ? "fas fa-sync fa-spin" : "fa fa-check-square"}`} />Finalizar cadastro
											</button>
										</div>
									}
								</div>

							</form>
						)}
					</Formik>

				</div>
			</div>
		</>
	);
}

export default connect(null, auth.actions)(Register)

